<template>
    <div class="vz-breadcrumbs">
        <div class="items">
            <router-link to="/dashboard" class="breadcrumb">Overzicht</router-link>
            <span></span>
            <transition name="fade-quick">
                <span class="separator" v-if="route.params.id || hasRoutePath()">
                    <material>chevron_right</material>
                </span>
            </transition>
            <transition name="fade-quick" mode="out-in">
                <router-link
                    :to="categoryRoute"
                    class="breadcrumb"
                    v-if="route.params.id && category?.name">
                    {{ $t(`subcategories.${category?.name}`) }}
                </router-link>
                <router-link
                    :to="route.path"
                    class="breadcrumb"
                    v-else-if="route.path && route.meta.title && hasRoutePath()">
                    {{ $t(route.meta.title as string) }}
                </router-link>
            </transition>
        </div>

        <div class="items">
            <transition name="fade-quick">
                <router-link :to="closeRoute" class="breadcrumb close" v-if="hasRoutePath()">
                    {{ $t('action.close') }}
                </router-link>
            </transition>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { type SubCategory, useCategoryStore } from '@/stores/category.store';

    const categoryStore = useCategoryStore();
    const route = useRoute();

    const category = ref<SubCategory | undefined>(undefined);
    const categoryRoute = computed(() => `/dashboard/category/${route.params.id}`);
    const closeRoute = computed(() => {
        if (route.params.fileId) {
            return `/dashboard/category/${route.params.id}`;
        }

        return '/';
    });

    const hasRoutePath = () => route.path !== '/dashboard' && route.path !== '/dashboard/overview';

    const getCategory = () => {
        if (route.params.id) {
            category.value = categoryStore.findSubCategory(route.params.id as string);
        }
    };

    getCategory();

    watchEffect(() => getCategory());
</script>

<style scoped lang="scss">
    .vz-breadcrumbs {
        background-color: hsl(var(--grey-200));
        display: flex;
        height: 3.07rem;
        justify-content: space-between;
        z-index: 20;

        .items {
            align-items: center;
            display: flex;
            gap: 0.25rem;
            padding: 0.5rem 1rem;
        }

        .separator {
            color: hsl(var(--grey-900));
            width: 1.5rem;
        }

        .breadcrumb {
            margin-block-start: -0.0625rem;
            -webkit-tap-highlight-color: transparent;

            &:hover,
            &:focus {
                color: hsl(var(--primary-700));
                text-decoration: underline;
            }

            &.close {
                font-weight: 700;
            }
        }
    }
</style>
