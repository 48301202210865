<template>
    <v-onboarding-wrapper
        :options="onboardingOptions"
        class="v-onboarding"
        ref="wrapper"
        :steps="steps"
        @finish="finishOnboarding"
        @exit="exitOnboarding" />

    <main ref="main">
        <vz-menu />
        <div class="main-content">
            <vz-breadcrumbs />
            <nuxt-page v-slot="{ Component, route }">
                <transition name="fade" mode="out-in">
                    <component :is="Component" :key="route.params.id" />
                </transition>
            </nuxt-page>
        </div>
    </main>
</template>

<script setup lang="ts">
    import { VOnboardingWrapper, useVOnboarding, type StepEntity } from 'v-onboarding';
    import 'v-onboarding/dist/style.css';
    import type { CloudFile } from '~/types/CloudFile';

    const { t } = useI18n();
    const router = useRouter();
    const configStore = useConfigStore();
    const categoryStore = useCategoryStore();
    const fileStore = useFileStore();

    onMounted(async () => {
        try {
            const configFileId = await configStore.getConfigFileId();

            const url = `/api/files/${configFileId}/content`;
            const configFile = await $fetch<Config>(url);

            if (configFile) {
                configStore.config = configFile;

                categoryStore.mergeWithConfig(configFile.categories);
            } else {
                configStore.createConfigFile();
            }
        } catch (error) {
            console.log(error.statusCode);
        }
    });

    const wrapper = ref(null);
    const exitedOnboarding = ref(false);
    const { start, finish, goToStep } = useVOnboarding(wrapper);

    const onboardingOptions = {
        overlay: {
            borderRadius: 5,
        },
        labels: {
            previousButton: 'Vorige',
            nextButton: 'Volgende',
            finishButton: 'Klaar',
        },
    };

    const welcomeStep: StepEntity = {
        attachTo: { element: '.vz-appbar' },
        options: {
            popper: {},
            labels: {
                previousButton: 'Vorige',
                finishButton: 'Sluiten',
                nextButton: 'Start',
            },
        },
        content: {
            title: t('onboarding.welcome.title'),
            description: t('onboarding.welcome.description'),
        },
    };

    const steps: StepEntity[] = [
        {
            attachTo: { element: '.user-info' },
            content: {
                title: t('onboarding.userInfo.title'),
                description: t('onboarding.userInfo.description'),
            },
        },
        {
            attachTo: {
                element: '.vz-breadcrumbs',
            },
            content: {
                title: t('onboarding.breadcrumbs.title'),
                description: t('onboarding.breadcrumbs.description'),
            },
            on: {
                async afterStep() {
                    // await router.push(`/dashboard/category/5eb11eed-cc36-4914-afcc-4221f582420c`);
                },
            },
        },
        {
            attachTo: { element: '.close' },
            content: {
                title: t('onboarding.closeButton.title'),
                description: t('onboarding.closeButton.description'),
            },
            options: {
                overlay: {
                    padding: 10,
                },
            },
        },
        {
            attachTo: { element: '.file-upload' },
            content: {
                title: t('onboarding.file-upload.title'),
                description: t('onboarding.file-upload.description'),
            },
            options: {
                overlay: {
                    padding: 10,
                },
            },
            on: {
                async beforeStep() {
                    if (!fileStore.filelist.length) return;

                    fileStore.filelist = [];
                    return delay(500);
                },
            },
        },
        {
            attachTo: { element: '.file' },
            content: {
                title: t('onboarding.file.title'),
                description: t('onboarding.file.description'),
            },
            on: {
                async beforeStep() {
                    const demoFile: CloudFile = {
                        id: 'demo-file',
                        name: 'Demo file',
                        createdTime: new Date().toISOString(),
                        modifiedTime: new Date().toISOString(),
                        kind: 'drive#file',
                        type: 'file',
                        mimeType: 'image/webp',
                        properties: {
                            title: 'Demo bestand',
                        },
                    };

                    fileStore.$patch({ filelist: [demoFile] });
                    return delay(100);
                },
                async afterStep() {
                    fileStore.filelist = [];
                    return delay(500);
                },
            },
        },
    ];

    const screenWidth = useWindowSize().width;

    const delay = async (ms: number): Promise<void> =>
        new Promise((resolve) => setTimeout(resolve, ms));

    onMounted(() => {
        if (screenWidth.value < 1260) {
            steps.unshift({
                attachTo: { element: '.hb' },
                content: {
                    title: t('onboarding.mobileMenu.title'),
                    description: t('onboarding.mobileMenu.description'),
                },
            });
        }

        steps.unshift(welcomeStep);

        if (getOnboardingCookie()) {
            return;
        }

        start();
    });

    const getOnboardingCookie = () => {
        const cookie = document.cookie.split('; ').find((row) => row.startsWith('onboarding='));

        return cookie?.split('=')[1] === 'true';
    };

    const finishOnboarding = () => {
        if (exitedOnboarding.value) return;

        const maxAge = 365 * 24 * 60 * 60; // 1 year
        document.cookie = `onboarding=true; max-age=${maxAge}`;
        router.push('/');
    };

    const exitOnboarding = () => {
        exitedOnboarding.value = true;

        // User exited onboarding without finishing
        const maxAge = 30 * 24 * 60 * 60; // 30 days
        document.cookie = `onboarding=true; max-age=${maxAge}`;
        router.push('/');
    };

    defineOptions({
        name: 'vz-layout-main',
    });
</script>

<style scoped lang="scss">
    /* stylelint-disable selector-class-pattern */

    main {
        align-items: start;
        display: grid;
        grid-template: minmax(0, 1fr) / 0 1fr;
        grid-template-areas: 'menu main-content';
        overflow: hidden;

        @include breakpoint('large') {
            grid-template-columns: max-content 1fr;
        }
    }

    .main-content {
        display: grid;
        grid-area: main-content;
        grid-template-columns: 1fr;
        grid-template-rows: 3.07rem minmax(0, 1fr);
        height: 100%;
        overflow: hidden;
    }

    :deep(.vz-menu) {
        grid-area: menu;
        height: 100%;
    }

    .v-onboarding {
        --v-onboarding-overlay-fill: hsl(var(--primary-overlay));
        --v-onboarding-step-arrow-background: hsl(var(--grey-200));
        --v-onboarding-overlay-opacity: 0.6;
        --v-onboarding-step-z: 700;
        --v-onboarding-overlay-z: 600;

        :deep(.v-onboarding-item__header) {
            color: hsl(var(--primary-700));
        }

        :deep(svg.h-4.w-4) {
            font-size: 3rem;

            path {
                font-size: 2rem;
                height: 2rem;
                width: 2rem;
            }
        }

        :deep(.v-onboarding-item__header-close) {
            background-color: hsl(var(--grey-300));
            border-color: transparent;
            color: hsl(var(--primary-500));
            cursor: pointer;
            outline: none;
            transition:
                background-color 300ms ease,
                color 300ms ease;

            &:hover,
            &:focus {
                background-color: hsl(var(--grey-400) / 50%);
                color: hsl(var(--primary-300));
            }

            &::after {
                content: '×';
                font-size: 1.5rem;
                margin-top: 0.125rem;
                position: absolute;
            }
        }

        :deep(.v-onboarding-item) {
            background-color: hsl(var(--grey-200));
            border-radius: 0.625rem;
            box-shadow: hsl(var(--primary-900) / 35%) 0 5px 15px;
        }

        :deep(.v-onboarding-item__description) {
            font-size: 1rem;
        }

        :deep(.v-onboarding-item__actions button.v-onboarding-btn-primary) {
            background-color: hsl(var(--primary-500));
        }

        :deep(.v-onboarding-item__actions button) {
            &:focus {
                outline-color: transparent;
            }

            &:focus-visible {
                outline: none;
                outline-color: hsl(var(--primary-500));
                outline-offset: 3px;
            }
        }
    }
</style>
