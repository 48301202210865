<template>
    <div class="mobile-backdrop" @click="closeMenu"></div>
    <aside class="vz-menu" @dragover.prevent>
        <div class="menu-content scroll-gradient" v-auto-animate>
            <div v-for="category in categories" :key="category.id" class="category">
                <h2>
                    {{ $t(`categories.${category.name}`) }}
                </h2>
                <vz-subcategory-list :subcategories="subCategories(category.subcategories)" />
            </div>
        </div>
        <vz-footer />
    </aside>
</template>

<script setup lang="ts">
    const categoryStore = useCategoryStore();
    const router = useRouter();

    router.afterEach(() => {
        closeMenu();
    });

    const closeMenu = () => {
        document.querySelector('body')?.classList.remove('menu-open');
    };

    const categories = computed(() =>
        categoryStore.categories.filter((category) =>
            category.subcategories.some((subcategory) => subcategory.visible),
        ),
    );

    const subCategories = (subcategories: SubCategory[]) => {
        return subcategories
            .filter((subcategory) => subcategory.visible)
            .toSorted((a, b) => {
                if (a.sort === b.sort) {
                    return a.name.localeCompare(b.name);
                }

                const aSort = a.sort ?? 0;
                const bSort = b.sort ?? 0;
                return aSort - bSort;
            });
    };

    defineOptions({
        name: 'vz-menu',
    });
</script>

<style scoped lang="scss">
    h2 {
        background-color: var(--container-bg);
        font-size: 1.5rem;
        margin: 0;
        padding-block-start: 2rem;
        padding-inline-start: 1rem;
        position: sticky;
        top: 0;
        z-index: 5;
    }

    .mobile-backdrop {
        background-color: hsl(var(--primary-700));
        cursor: pointer;
        height: calc(100vh - var(--navbar-height, 4.3rem));
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: fixed;
        -webkit-tap-highlight-color: transparent;
        top: var(--navbar-height, 4.3rem);
        transition: opacity 300ms ease-in-out;
        width: 100%;
        z-index: 500;

        body.menu-open & {
            opacity: 0.5;
            pointer-events: all;
            transition: opacity 300ms 0ms ease-in-out;
        }
    }

    .vz-menu {
        --container-bg: hsl(var(--grey-100));

        background-color: var(--container-bg);
        display: grid;
        grid-template:
            [content-start] 'content' auto [content-end]
            [footer-start] 'footer' min-content [footer-end] / 1fr;
        height: calc(100vh - var(--navbar-height));
        isolation: isolate;
        justify-content: space-between;
        overflow: scroll;
        position: relative;
        transform: translateX(-100%);
        transition: transform 300ms ease-in-out;
        width: min(80vw, 450px);
        z-index: 500;

        body.menu-open & {
            transform: translateX(0);
        }

        @include breakpoint('large') {
            border-right: 1px solid hsl(var(--primary-100));
            transform: translateX(0);
        }

        .menu-content {
            background-color: var(--container-bg);
            grid-area: content;
            overflow: scroll;
            padding: 0;

            @include breakpoint(small) {
                padding-inline: 1rem;
            }

            @include breakpoint(medium) {
                padding-inline: 2rem;
            }
        }

        .vz-footer {
            grid-area: footer;
        }
    }
</style>
